<template>
  <v-btn icon :loading="loading" @click="$emit('refresh')">
    <v-icon>fas fa-redo</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "reportRefresh",
  data: () => ({
    loading: false
  }),
  methods: {
    async refresh() {
      this.loading = true;
      await this.$store.dispatch("fetchReports");
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
